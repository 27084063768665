import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "operate-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alertMessage = _resolveComponent("alertMessage")!
  const _component_klook_icon = _resolveComponent("klook-icon")!
  const _component_mail_outlined = _resolveComponent("mail-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_klook_phone_input = _resolveComponent("klook-phone-input")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_third_login = _resolveComponent("third-login")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_alertMessage, {
      message: _ctx.alert.mess,
      type: _ctx.alert.type,
      closable: _ctx.alert.closable,
      visible: _ctx.alert.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.alert.visible) = $event))
    }, null, 8, ["message", "type", "closable", "visible"]),
    _createVNode(_component_a_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.formRules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_klook_icon, {
          type: 'logo',
          size: [216, 36]
        }),
        _createVNode(_component_a_tabs, {
          activeKey: _ctx.form.login_type,
          "onUpdate:activeKey": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.login_type) = $event)),
          animated: false,
          onChange: _ctx.onLoginTypeChanged,
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, {
              tab: _ctx.$t('75597'),
              key: "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, { name: "username" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.form.username,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.username) = $event)),
                      size: "large",
                      placeholder: _ctx.$t('75602')
                    }, {
                      suffix: _withCtx(() => [
                        _createVNode(_component_mail_outlined)
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["tab"]),
            _createVNode(_component_a_tab_pane, {
              tab: _ctx.$t('75598'),
              key: "phone"
            }, {
              default: _withCtx(() => [
                (_ctx.form.login_type === 'phone')
                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                      key: 0,
                      name: "username"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_klook_phone_input, {
                          value: _ctx.form.username,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.username) = $event)),
                          size: "large",
                          set: _ctx.set
                        }, null, 8, ["value", "set"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["tab"])
          ]),
          _: 1
        }, 8, ["activeKey", "onChange"]),
        _createVNode(_component_a_form_item, {
          class: "input-last",
          name: "password"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_password, {
              value: _ctx.form.password,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.password) = $event)),
              size: "large",
              placeholder: _ctx.$t('75605'),
              autocomplete: ""
            }, null, 8, ["value", "placeholder"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_a_checkbox, {
            checked: _ctx.isRememberMe,
            onChange: _cache[5] || (_cache[5] = (e) => (_ctx.isRememberMe = e.target.checked))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('75617')), 1)
            ]),
            _: 1
          }, 8, ["checked"]),
          _createVNode(_component_router_link, {
            to: {
          name: 'forgetPassword',
          query: {
            type: _ctx.form.login_type,
            username: _ctx.form.username
          }
        }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('75620')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              class: "operate-btn",
              size: "large",
              type: "primary",
              loading: _ctx.isLoading,
              block: "",
              onClick: _ctx.login
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('75619')), 1)
              ]),
              _: 1
            }, 8, ["loading", "onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_third_login, { onOnStatusChange: _ctx.onThirdLoginStatusChange }, null, 8, ["onOnStatusChange"])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ], 64))
}