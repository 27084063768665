
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    type: String,
    message: String,
    closable: Boolean,
    visible: Boolean
  },
  setup(_, ctx) {
    const onClose = () => {
      ctx.emit('update:visible', false)
    }
    return {
      onClose
    }
  }
})
