import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "alert" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_alert = _resolveComponent("a-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.visible)
      ? (_openBlock(), _createBlock(_component_a_alert, {
          key: 0,
          message: _ctx.message,
          type: _ctx.type,
          closable: _ctx.closable,
          "after-close": _ctx.onClose
        }, null, 8, ["message", "type", "closable", "after-close"]))
      : _createCommentVNode("", true)
  ]))
}