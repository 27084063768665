
import { URLS, GOOGLE_CLIENT_ID } from '@/common/js/constant'
import { post } from '@/plugins'
import { getAPILang } from '@/utils'
// import { message } from 'ant-design-vue'
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { handleLoginSuccess } from './utils'

export default defineComponent({
  setup(props, ctx) {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      isInit: false
    })

    const handleCredentialResponse = (res: any) => {
      ctx.emit('onStatusChange', 'loading')
      post(
        URLS.loginWithGoogle,
        {
          id_token: res.credential
        },
        false
      )
        .then(() => {
          // KLOOK.GTMHookItem('Login|Sign in with Google|Successful');
          handleLoginSuccess(router)
          ctx.emit('onStatusChange', 'success')
        })
        .catch((err: any) => {
          // let msg = 'Google登录失败'

          // if (err) {
          //   msg = (typeof err === 'string' ? err : err.message) || 'Google登录失败'
          // }
          // message.error(msg)
          ctx.emit('onStatusChange', 'error', err)
        })
    }
    onMounted(() => {
      loadJs('https://accounts.google.com/gsi/client').then(() => {
        state.isInit = true
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse
        })

        window.google.accounts.id.renderButton(document.getElementById('google-login-button'), {
          size: 'medium',
          width: 200,
          locale: getAPILang(store.state.antLocale.locale.locale)
        })
      })
    })

    const loadJs = (src: string) => {
      return new Promise((resolve) => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = src
        document.body.appendChild(script)

        script.onload = (e) => {
          resolve(e)
        }
      })
    }
    return {
      ...toRefs(state)
    }
  }
})
