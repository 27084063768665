
import { useRouter } from 'vue-router'
import { defineComponent, nextTick, onMounted, reactive, ref, toRefs } from 'vue'
import { accountRules } from '@/common/js/form-rule/index'
import { URLS } from '@/common/js/constant/index'
import Cookie from 'js-cookie'
import ThirdLogin from './third-login.vue'
import { LS, md5 } from '@/utils/core'
import { post } from '@/plugins'
import { FormInstance } from 'ant-design-vue'
import KlookPhoneInput from '@/components/phone-input.vue'
import { MailOutlined } from '@ant-design/icons-vue'
import KlookIcon from '@/components/icon.vue'
import alertMessage from '@/components/alert-message.vue'
import { specialCode } from '@/plugins/axios'

export default defineComponent({
  components: {
    KlookIcon,
    ThirdLogin,
    KlookPhoneInput,
    MailOutlined,
    alertMessage
  },

  setup() {
    const router = useRouter()
    const formRef = ref<FormInstance | any>()

    const state = reactive({
      formRules: {},
      form: {
        login_type: 'email',
        username: '',
        password: '',
        err_message: '',
        err_type: ''
      },
      isRememberMe: false,
      isLoading: false,
      alert: {
        mess: '',
        type: '',
        closable: true,
        visible: false
      },
      set: false
    })

    onMounted(() => {
      const loginInfo: any = LS.get('loginInfo')

      if (loginInfo) {
        state.isRememberMe = true
        const items = JSON.parse(loginInfo)
        state.form.login_type = items.login_type
        nextTick(() => {
          state.form.username = items.username
          if (items.login_type === 'phone') {
            state.set = true
          }
        })
      }

      bindRules()
    })

    const bindRules = () => {
      state.formRules = accountRules.call(null, state.form)
    }

    /**
     * 登录类型发生改变
     * @param {String} val 当前类型
     */
    const onLoginTypeChanged = (val: string) => {
      state.form.login_type = val
      formRef.value.resetFields()
    }

    // 处理记住账密码
    const handleRememberMe = () => {
      if (state.isRememberMe) {
        LS.set(
          'loginInfo',
          JSON.stringify(
            Object.assign(state.form, {
              password: ''
            })
          )
        )
      } else {
        LS.remove('loginInfo')
      }
    }

    // 登录
    const login = () => {
      formRef.value.validate().then(() => {
        state.isLoading = true
        post(URLS.login, {
          login_type: state.form.login_type,
          username: state.form.username,
          password: md5(state.form.password)
        })
          .then(() => {
            Cookie.set('isLogin', '1', {
              path: '/',
              expires: 5
            })
            handleRememberMe()
            router.push({ path: 'home' })
          })
          .catch((err) => {
            setErrMessage(err)
          })
          .finally(() => {
            state.isLoading = false
          })
      })
    }

    // 设置错误信息
    const setErrMessage = (res: any) => {
      if (specialCode[res.code]) {
        switch (specialCode[res.code]) {
          case '密码错误':
            state.form.err_message = res.message
            state.form.err_type = 'password'
            formRef.value.validateFields()
            break
          case '用户不存在':
            state.form.err_message = res.message
            state.form.err_type = 'username'
            formRef.value.validateFields()
            break
          case '未绑定google':
            state.alert = {
              ...state.alert,
              mess: res.message,
              type: 'warning',
              closable: true,
              visible: true
            }
            break
          case 'lock账户':
            state.alert = {
              ...state.alert,
              mess: res.message,
              type: 'error',
              closable: false,
              visible: true
            }
            break

          default:
            break
        }
        setTimeout(() => {
          state.form.err_message = ''
          state.form.err_type = ''
        }, 1000)
      }
    }

    const onThirdLoginStatusChange = (status: string, err?: any) => {
      state.isLoading = status === 'loading'
      if (status === 'error') {
        setErrMessage(err)
      }
    }
    return {
      ...toRefs(state),
      login,
      handleRememberMe,
      onLoginTypeChanged,
      onThirdLoginStatusChange,
      formRef
    }
  }
})
