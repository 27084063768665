import { LS } from '@/utils/core';
import Cookie from 'js-cookie';

const handleLoginSuccess = (router: any) => {
    const query = new URLSearchParams(window.location.search);
    const redirectUrl: string = query.get('redirect_url') || '';
    Cookie.set('isLogin', '1', {
        path: '/',
        expires: 5,
    });
    LS.set('__from', 'login'); // 添加一个标志，表示进入的时候会自动跳转到hotel merchant
    // 重定向到目标页面或者回到首页
    if (_isValidRedirectUrl(redirectUrl)) {
        window.location.href = redirectUrl;
    } else {
        router.push({ path: 'home' });
    }
};

/**
 * 是否是有效的url跳转链接
 * @param {String} str url字符串
 * @return {Boolean}
 */
function _isValidRedirectUrl(str: string) {
    let url: any = '';

    if (!str) {
        return false;
    }

    try {
        url = new URL(str);
    } catch {
        return false;
    }

    if (url.host !== window.location.host) {
        return false;
    }

    return true;
}

export { handleLoginSuccess };
